import { useState, useCallback } from "react";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Nav from "../components/nav";
import PortalDrawer from "../components/portal-drawer";
import Footer from "../components/footer";
import Border from "../components/border";
import BoxSectionV1 from "../components/boxSectionV1";
import AugmentedHead from "../components/augmentedHead";

const Evenement = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const openNav = useCallback(() => {
    setNavOpen(true);
  }, []);

  const closeNav = useCallback(() => {
    setNavOpen(false);
  }, []);

  function openPDF(path) {
    window.open(path, "_blank");
  };

  const GridImage = ({ image, text, pdf }) => {

    var boolean;
    if (pdf != null) {
      pdf.length > 1 ? boolean = false : boolean = true;
    }

    return (
      <div class="w-[80vw] h-[80vw] md:w-[20vw] md:h-[20vw] group relative">
        <img class="h-full w-full object-cover rounded-xl group-hover:brightness-50 group-hover:blur-[2px]" src={image} />
        <div class="absolute h-full w-full flex flex-col items-center justify-evenly bottom-0 opacity-0 group-hover:opacity-100">
          <p class="text-white text-[25px] lg:text-xl">{text}</p>
          {boolean == true ?
            <div onClick={() => openPDF(pdf[0])} className="text-black flex items-center justify-center bg-white rounded-[50px] text-center text-[25px] lg:text-[20px] opacity-0 group-hover:opacity-100 w-[80%] h-[20%] cursor-pointer">Voir le livret sportif</div>
            : <div className="flex flex-row items-center justify-evenly w-[100%] h-[20%]">
              <div onClick={() => openPDF(pdf[0])} className="text-black flex items-center justify-center bg-white rounded-[50px] text-center text-[25px] lg:text-[25px] opacity-0 group-hover:opacity-100 w-[40%] h-full cursor-pointer">
                Livret H
              </div>
              <div onClick={() => openPDF(pdf[1])} className="text-black flex items-center justify-center bg-white rounded-[50px] text-center text-[25px] lg:text-[25px] opacity-0 group-hover:opacity-100 w-[40%] h-full cursor-pointer">
                Livret F
              </div>
            </div>}
        </div>
      </div>
    )
  };

  const images = [
    { image: "../sport/athle.jpg", text: "Athlétisme", pdf: null },
    { image: "../sport/bad.jpg", text: "Badminton", pdf: ["../pdf/2025 - Badminton.pdf"] },
    { image: "../sport/basket.jpg", text: "Basketball", pdf: ["../pdf/2025 - Basket M.pdf", "../pdf/2025 - Basket F.pdf"] },
    { image: "../sport/escalade.jpeg", text: "Escalade", pdf: ["../pdf/2025 - Escalade.pdf"] },
    { image: "../sport/escrime.jpg", text: "Escrime", pdf: null },
    { image: "../sport/foot.jpg", text: "Football", pdf: ["../pdf/2023 - Foot M.pdf", "../pdf/2025 - Foot F.pdf"] },
    { image: "../sport/hand.jpg", text: "Handball", pdf: ["../pdf/2025 - Hand M.pdf", "../pdf/2025 - Hand F.pdf"] },
    { image: "../sport/judo.jpg", text: "Judo", pdf: ["../pdf/2025 - Judo.pdf"] },
    { image: "../sport/padel.jpg", text: "Padel", pdf: ["../pdf/2025 - Padel.pdf"] },
    { image: "../sport/petanque.jpg", text: "Pétanque", pdf: ["../pdf/2025 - Petanque.pdf"] },
    { image: "../sport/piscine.jpg", text: "Natation", pdf: ["../pdf/2025 - Natation.pdf"] },
    { image: "../sport/pompom.jpg", text: "Pom-pom girls", pdf: null },
    { image: "../sport/rugby.jpg", text: "Rugby", pdf: ["../pdf/2025 - Rugby M.pdf", "../pdf/2025 - Rugby F.pdf"] },
    { image: "../sport/spike.jpg", text: "Spikeball", pdf: ["../pdf/2025 - Spikeball.pdf"] },
    { image: "../sport/street workout.jpg", text: "Street Workout", pdf: ["../pdf/2025 - Street Workout.pdf"] },
    { image: "../sport/tennis.jpeg", text: "Tennis", pdf: ["../pdf/2025 - Tennis.pdf"] },
    { image: "../sport/tennisTable.jpg", text: "Tennis de table", pdf: ["../pdf/2025 - Tennis de table.pdf"] },
    { image: "../sport/trail.jpg", text: "Trail", pdf: ["../pdf/2025 - Trail.pdf"] },
    { image: "../sport/ultimate.jpg", text: "Ultimate", pdf: ["../pdf/2025 - Ultimate.pdf"] },
    { image: "../sport/volley.jpg", text: "Volleyball", pdf: ["../pdf/2025 - Volley M.pdf", "../pdf/2025 - Volley F.pdf"] },
    { image: "../sport/waterpolo.jpeg", text: "Waterpolo", pdf: ["../pdf/2025 - Water-polo.pdf"]},
  ]; 



  const position = [
    { pos: [45.7787654143484, 4.922370855794717], text: '<h1>ENTPE</h1><li>Natation</li><li>Waterpolo</li>' },
    { pos: [45.72167819810474, 4.827423548668503], text: `<h1>Gerland</h1><li>Foot Masculin</li><li>Athétisme</li>` },
    { pos: [45.72113289799306, 4.91914417750158], text: `<h1>Bron Lyon 2</h1><li>Badminton</li><li>Volley Féminin</li><li>Spikeball</li><li>Tennis</li>` },
    { pos: [45.783644781405656, 4.873065937328141], text: '<h1>La Doua</h1><li>Volley Masculin</li><li>Basket Masculin</li><li>Basket Féminin</li><li>Rugby Masculin</li><li>Escalade</li>' },
    { pos: [45.78274792101876, 4.766360423159446], text: '<h1>Centrale</h1><li>Hand Masculin</li><li>Trail</li><li>Tennis</li><li>Ultimate</li><li>Rugby Féminin</li><li>Escrime</li>' },
    { pos: [45.78654484526432, 4.764118145877554], text: '<h1>EM</h1><li>Hand Masculin</li>' },
    { pos: [45.78364736456398, 4.786114313359468], text: '<h1>Ecully Arts Martiaux</h1><li>Judo</li>' },
    { pos: [45.7743910503545, 4.77224190159341], text: '<h1>Ecully Tennis de Table</h1><li>Pétanque</li><li>Tennis de Table</li>' },
    { pos: [45.78362490947725, 4.788263546554276], text: '<h1>Ecully Cévert</h1><li>Hand Féminin</li>' }
  ];

  const customMarkerIcon = L.icon({
    iconUrl: '../marker.webp',
    iconSize: [18.9, 32], // size of the icon
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -32] // point from which the popup should open relative to the iconAnchor
  });


// <img className="w-[350vw] items-center inline-block" src="../timeline.png" /> Ligne pour insérer la timeline quand elle sera prête

  return (
    <>
      <div className="bg-gray-100 w-screen flex flex-col items-start justify-start text-center text-white font-source-sans-pro">

        <AugmentedHead
          openNav={openNav}
          themeColor="gray-600"
          textColor="white"
          mainText="Découvrez le Challenge 2025"
          mainImage="../waterpolo.png"
          navName="Evenement"
          items={[
            { text: "Sports", link: "sport" },
            { text: "Timeline", link: "timeline" },
            { text: "Sites", link: "site" },
            { text: "Soirées", link: "soiree" }
          ]} />

        <Border />

        <BoxSectionV1 id="sport" text={"Sports"} h={"h-[1900vw] lg:h-[330vh]"}>
          <div className="w-[88%] md:w-[95%] h-[45%] md:h-[70%] flex flex-col items-center justify-start text-white mx-10 mt-10 text-[20px] md:text-[35px]">
                Attention, il s'agit ici des livrets sportifs de l'édition 2024. Ils sont encore disponibles pour permettre aux sportifs d'avoir un premier aperçu de la journée. Les livrets de l'édition 2025 vont arriver dans les prochains jours.
          </div>
          <div className="flex items-start justify-center">
            <div className="w-[90%] h-[70%] items-center justify-center grid md:grid-cols-4 grid_cols-1 gap-[5vh]">
                {images.map(({ image, text, pdf }) =>
                  <GridImage image={image} text={text} pdf={pdf} />
                )}
            </div>
          </div>
        </BoxSectionV1>

        <Border />

        <BoxSectionV1 id="timeline" text={"Timeline"} h={"h-[150vh] lg:h-[180vh]"}>
          <div className="w-[100vw] justify-start items-center flex overflow-x-auto overflow-y-none">
          <div className="w-[88%] md:w-[95%] h-[45%] md:h-[70%] flex flex-col items-center justify-start text-white mx-10 mt-10 text-[20px] md:text-[35px]">
                La TimeLine 2025 est en cours de réalisation. Elle sera disponible ultérieurement.
          </div>
          </div>
        </BoxSectionV1>

        <Border />

        <BoxSectionV1 id="site" text={"Sites"} h={"h-[140vh] lg:h-[130vh]"}>
          <div className="w-full h-screen flex justify-center items-center">
            <div className="w-[90vw] h-[90vw] md:w-[100vh] md:h-[90vh] z-0">
              <MapContainer style={{ height: '100%', width: '100%' }} center={[45.764043, 4.835659]} zoom={11} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&amp;copy <a href="http://outdoors.org/copyright">OpenStreetMap</a> contributors'
                  url="http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                />

                {position.map(({ pos, text }) => (
                  <Marker position={pos} icon={customMarkerIcon}>
                    <Popup>
                      <div dangerouslySetInnerHTML={{ __html: text }} />
                    </Popup>
                  </Marker>
                ))}

              </MapContainer>
            </div>
          </div>
        </BoxSectionV1>

        <Border />

        <BoxSectionV1 id="soiree" text={"Soirée"} h={"h-[150vh] md:h-[120vh]"}>
          <div className="w-[100%] h-[100%] items-center justify-start md:justify-evenly flex flex-col md:flex-row gap-[50px]">
            <div className="w-[80%] md:w-[50%] h-[45%] md:h-[70%] flex flex-col items-center justify-start text-white text-[20px] md:text-[35px]">
              Entrez dans un monde de sensations vibrantes lors de la soirée fluo ! Rejoignez les 3000 participants pour une nuit envoûtante de musique, de lumières et de couleurs intenses. Laissez-vous séduire par l'ambiance enivrante de cette soirée, où chaque battement de musique vous fait vibrer le corps et l'âme. Dansez jusqu'au bout de la nuit et laissez les surprises vous transporter vers de nouveaux horizons.
            </div>
            <img className="w-[80%] md:w-[40%] h-[30%] md:h-[95%] object-cover object-center rounded-3xl" src="../soiree_photo.jpeg" />
          </div>
        </BoxSectionV1>

        <Border />

        <Footer />

      </div>
      {isNavOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeNav}
        >
          <Nav onClose={closeNav} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Evenement;
