import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Nav from "../components/nav";
import PortalDrawer from "../components/portal-drawer";
import Footer from "../components/footer";
import Border from "../components/border";
import BorderV2 from "../components/borderV2";
import BoxSectionV1 from "../components/boxSectionV1";
import AugmentedHead from "../components/augmentedHead";


const Participer = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const openNav = useCallback(() => {
    setNavOpen(true);
  }, []);

  const closeNav = useCallback(() => {
    setNavOpen(false);
  }, []);

  return (
    <>
      <div className="w-screen bg-gray-500 flex flex-col items-start justify-start font-source-sans-pro">

        <AugmentedHead
          openNav={openNav}
          themeColor="white"
          textColor="white"
          mainText="Participez au Challenge 2025"
          mainImage="../basket.png"
          navName="Participer"
          items={[
            { text: "Vous êtes étudiant ?", link: "etudiant" },
            { text: "Vous représentez un BDS ?", link: "BDS" },
            { text: "Vous représentez une entreprise ?", link: "entreprise" }
          ]} />

        <BorderV2 />

        <BoxSectionV1 id="etudiant" text="Vous êtes étudiant ?" h={"h-[130vh]"}>
          <div className="w-[100%] h-[70%] items-center justify-center flex flex-col gap-[50px]">
            <div className="w-[90%] flex flex-col items-center justify-start text-white text-[25px] md:text-xl">
              Pour participer au Challenge vous devez faire partie d'une des écoles invités.
              Votre inscription se fait auprès de votre école. Contactez votre bureau des sports pour plus d'informations.
              Pour les étudiants de Centrale Lyon il faut s'incrire sur le Challenger.
            </div>
            <div className="flex items-center justify-start w-[90%]">
              <div
                className="cursor-pointer h-[8vh] w-[50vw] md:w-[25vw] [text-decoration:none] rounded-2xxs bg-gradient-to-r from-[#f97316] to-[#92400e] transition ease-in-out delay-150 hover:scale-110 duration-300 hover:from-[#d97706] hover:to-[#a16207] flex flex-row items-center justify-center text-sm text-white "
                onClick={useCallback(() => { window.open("https://challenger.challenge-centrale-lyon.fr/login");})}>
                <p className="relative w-[80%] text-center">Challenger</p>
              </div>
            </div>
          </div>
        </BoxSectionV1>

        <BorderV2 />

        <BoxSectionV1 id="BDS" text="BDS ?" h={"h-[130vh]"}>
        <div className="w-[100%] h-[70%] items-center justify-center flex flex-col gap-[50px]">
            <div className="w-[90%] flex flex-col items-center justify-start text-white text-[25px] md:text-xl">
              Les bureaux des sports inscrivent leur étudiants sur le Challenger.
              Si votre bureau des sports n'est pas invité mais que vous souhaitez participer au Challenge vous nous en faire la demande.
              Pour plus d'information vous pouvez nous contacter sur facebook et instagram.
            </div>
            <div className="flex flex-col gap-[30px] md:flex-row items-center justify-evenly w-[90%]">
              <div
                className="cursor-pointer h-[8vh] w-[50vw] md:w-[25vw] [text-decoration:none] rounded-2xxs bg-gradient-to-r from-[#f97316] to-[#92400e] transition ease-in-out delay-150 hover:scale-110 duration-300 hover:from-[#d97706] hover:to-[#a16207] flex flex-row items-center justify-center text-sm lg:text-sm text-white"
                onClick={useCallback(() => { window.open("https://challenger.challenge-centrale-lyon.fr/login");})}>
                <p className="relative w-[25vw] text-center">Challenger</p>
              </div>
              <div
                className="cursor-pointer h-[8vh] w-[50vw] md:w-[25vw] [text-decoration:none] rounded-2xxs bg-gradient-to-r from-[#f97316] to-[#92400e] transition ease-in-out delay-150 hover:scale-110 duration-300 hover:from-[#d97706] hover:to-[#a16207] flex flex-row items-center justify-center text-sm lg:text-sm text-white"
                onClick={useCallback(() => { window.open("https://www.instagram.com/challengecentralelyon/");})}>
                <p className="relative w-[25vw] text-center">Instagram</p>
              </div>
              <div
                className="cursor-pointer h-[8vh] w-[50vw] md:w-[25vw] [text-decoration:none] rounded-2xxs bg-gradient-to-r from-[#f97316] to-[#92400e] transition ease-in-out delay-150 hover:scale-110 duration-300 hover:from-[#d97706] hover:to-[#a16207] flex flex-row items-center justify-center text-sm lg:text-sm text-white"
                onClick={useCallback(() => { window.open("https://www.facebook.com/ChallengeCentraleLyon");})}>
                <p className="relative w-[25vw] text-center">Facebook</p>
              </div>
            </div>
          </div>
        </BoxSectionV1>

        <BorderV2 />

        <BoxSectionV1 id="entreprise" text="Entreprise ?" h={"h-[130vh]"} >
        <div className="w-[100%] h-[70%] items-center justify-center flex flex-col gap-[50px]">
            <div className="w-[90%] flex flex-col items-center justify-start text-white text-[25px] md:text-xl">
              Si vous souhaitez sponsoriser l'évenement vous pouvez nous contacter par mail.
              Vous pouvez consulter le dossier de partenariat en cliquant sur le bouton ci-dessous.
              Vous trouverez notamment à la dernière pages les contacts des responsables des partenariats.
            </div>
            <Link className="flex items-center justify-start w-[90%] [text-decoration:none]"
              to="/devenirpartenaire">
              <div
                className="cursor-pointer h-[10vh] lg:h-[8vh] w-[50vw] md:w-[25vw] rounded-2xxs bg-gradient-to-r from-[#f97316] to-[#92400e] transition ease-in-out delay-150 hover:scale-110 duration-300 hover:from-[#d97706] hover:to-[#a16207] flex flex-row items-center justify-center text-sm text-white">
                <p className="relative w-[25vw] text-center">Dossier partenariat</p>
              </div>
            </Link>
          </div>
        </BoxSectionV1>

        <BorderV2 />

        <Footer />

      </div>
      {
        isNavOpen && (
          <PortalDrawer
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Left"
            onOutsideClick={closeNav}
          >
            <Nav onClose={closeNav} />
          </PortalDrawer>
        )
      }
    </>
  );
};

export default Participer;
